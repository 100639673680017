<script setup lang="ts">
import Checkbox from '@/Components/Checkbox.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm, usePage } from '@inertiajs/vue3';
import { onMounted, onUnmounted, ref } from 'vue';
import { OhVueIcon } from 'oh-vue-icons';
import SecondaryButton from '@/Components/SecondaryButton.vue';
import Swal from 'sweetalert2';

const { message } = usePage().props as any;

defineProps<{
  canResetPassword?: boolean;
  status?: string;
}>();

const form = useForm({
  nik: '',
  password: '',
  remember: false,
});

const submit = () => {
  form.post(route('login'), {
    onFinish: () => {},
  });
};

const globe = ref<any>();

onMounted(() => {
  if ((window as any).VANTA && (window as any).VANTA.NET) {
    globe.value = (window as any).VANTA.NET({
      el: '#vanta_net',
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x2283ac,
      backgroundColor: 0x2f2a61,
      points: 12.0,
      maxDistance: 21.0,
    });
  }

  if (message != null) {
    Swal.fire('Berhasil', 'Password berhasil direset, dan Password baru dikirimkan ke WhatsApp anda', message.type);
  }
});

const showPassword = ref(false);

onUnmounted(() => {
  globe.value.destroy();
});
</script>

<template>
  <Head title="Log in" />
  <div id="vanta_net" class="flex items-center justify-center w-screen h-screen gap-5">
    <div class="w-[500px] mx-4">
      <div v-if="status" class="mb-4 text-sm font-medium text-green-600">
        {{ status }}
      </div>

      <form @submit.prevent="submit" class="w-full bg_login p-6">
        <div class="mb-4 flex justify-center">
          <img src="/assets/images/SuperApps_tiny.png" class="w-[250px]" />
        </div>
        <div>
          <div>
            <InputLabel for="nik" value="NIK" class="text-white" />
            <TextInput id="nik" type="text" class="block w-full mt-1" v-model="form.nik" required autofocus autocomplete="nik" placeholder="Masukkan NIK" />
            <InputError class="mt-2" :message="form.errors.nik" />
          </div>

          <div class="mt-2">
            <InputLabel for="password" value="Password" class="text-white" />

            <div class="relative">
              <input :type="showPassword ? 'text' : 'password'" v-model="form.password" required autocomplete="password" class="mt-1 block w-full rounded-md shadow-sm pr-[50px] focus:ring-2 focus:ring-green-600 border-none" placeholder="Masukkan Password" />
              <div class="absolute right-0 top-0 bottom-0 flex items-center w-[50px] justify-center">
                <button type="button" @click="() => (showPassword = !showPassword)">
                  <OhVueIcon v-if="showPassword" name="io-eye-off" />
                  <OhVueIcon v-else name="io-eye" />
                </button>
              </div>
            </div>

            <InputError class="mt-2" :message="form.errors.password" />
          </div>
        </div>
        <div class="mt-6">
          <div class="flex items-start justify-between mt-4">
            <label class="flex items-center">
              <Checkbox name="remember" v-model:checked="form.remember" />
              <span class="text-sm text-white ms-2">Ingat sesi login</span>
            </label>
            <Link href="/reset_password" class="text-sm text-white underline rounded-md hover:text-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"> Lupa Password? </Link>
          </div>

          <div class="flex items-center justify-between mt-2">
            <div>
              <img class="w-[175px]" src="/assets/images//logo_n4.png" />
            </div>
            <PrimaryButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing"> Log in </PrimaryButton>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style>
.bg_login {
  background: rgba(0, 0, 0, 0.05);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.25);
}
</style>
